<template>
  <!-- <fun-buy
    v-if="
      this.cart[0].products[0].product_category.productcategory_id ===
      CATEGORY.Event
    "
  /> -->

  <race-buy
    v-if="
      this.cart[0].products[0].product_category.productcategory_id ===
      CATEGORY.RACE
    "
  />
  <general-buy v-else />
</template>

<script>
import { mapGetters } from "vuex";
import GeneralBuy from "@/components/Buys/GeneralBuy.vue";
import RaceBuy from "@/components/Buys/RaceBuy.vue";
import { CATEGORY } from "@/config/category";

export default {
  name: "BuyView",

  data() {
    return {
      CATEGORY: CATEGORY,
    };
  },

  components: {
    GeneralBuy,
    RaceBuy,
  },

  created() {},

  computed: {
    ...mapGetters(["cart"]),
  },
  methods: {},
};
</script>

<style scoped>
.buy-timer {
  background-color: #ec7100 !important;
  color: antiquewhite;
}
</style>