export default {
  quantity: {
    isOutOfStock: (type) => {
      return (
        type.is_limited &&
        type.producttype_qty - type.producttype_booked_qty <= 0
      );
    },
    isLessThanMinOrder: (type) => {
      return (
        type.is_limited &&
        type.producttype_qty - type.producttype_booked_qty <
          type.producttype_minimum_order
      );
    },
    maxBuy: (total) => {
      return total > 20 ? true : false;
    },
  },
  cart: {
    canAddToCart: (type, cartQty) => {
      return (
        cartQty < type.producttype_maximum_order &&
        (!type.is_limited ||
          cartQty < type.producttype_qty - type.producttype_booked_qty)
      );
    },
    canReduceFromCart: (type, cartQty) => {
      return cartQty > type.producttype_minimum_order;
    },
  },
  input: {
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isIdentity(evt, type) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (type === "passport") {
        if (
          (charCode < 48 || charCode > 57) &&
          (charCode < 65 || charCode > 90) &&
          (charCode < 97 || charCode > 122)
        ) {
          evt.preventDefault();
        }
      } else if (type == "ktp" || type == "sim") {
        if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46
        ) {
          evt.preventDefault();
        }
      } else {
        return true;
      }
    },
  },
};
