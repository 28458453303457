<template>
  <v-container v-if="isLoading">
    <v-row>
      <v-spacer></v-spacer>
      <v-col>
        <div class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
  <no-product v-else-if="!isLoading && !this.detail_product" />
  <event-detail
    v-else-if="
      !isLoading &&
      [
        CATEGORY.KONSER,
        CATEGORY.EVENT,
        CATEGORY.OLAHRAGA,
        CATEGORY.RACE,
      ].includes(this.detail_product.product_category.productcategory_id)
    "
    :products="this.detail_product"
    :category="
      !isLoading
        ? this.detail_product.product_category.productcategory_id
        : null
    "
    :is-loading="isLoading"
  />
  <non-event-detail
    v-else-if="
      !isLoading &&
      [CATEGORY.ATRAKSI, CATEGORY.HEALING, CATEGORY.TAMAN_HIBURAN].includes(
        this.detail_product.product_category.productcategory_id
      )
    "
    :products="this.detail_product"
    :category="
      !isLoading
        ? this.detail_product.product_category.productcategory_id
        : null
    "
    :is-loading="isLoading"
  />
  <general-detail
    v-else
    :products="this.detail_product"
    :category="'general'"
    :is-loading="isLoading"
  />
</template>

<script>
import NoProduct from "@/components/Details/NoProduct.vue";
import GeneralDetail from "@/components/Details/General.vue";
import EventDetail from "@/components/Details/Event.vue";
import NonEventDetail from "@/components/Details/NonEvent.vue";
import { CATEGORY } from "@/config/category";
import { mapActions, mapGetters } from "vuex";
import { toRaw } from "vue";
import { utcToLocale } from "@/utils/dateFormatter";
import moment from "moment";
import Cookies from "js-cookie";

export default {
  name: "DetailView",

  components: {
    NoProduct,
    GeneralDetail,
    EventDetail,
    NonEventDetail,
  },

  computed: {
    ...mapGetters(["cart"]),
    getDataType() {
      let data = [];
      data.push(
        toRaw(
          this.detail_product.product_types.find(
            (product) => product.producttype_name === this.selectedType
          )
        )
      );
      return data;
    },
  },

  async created() {
    this.emptyCart();

    this.getProduct();

    this.cart.filter((cart) => cart.product_id === this.$route.params.id);
  },

  data() {
    return {
      offsetTop: 0,
      width: 1000,
      aspectRatio: 16 / 9,
      default_image: null,
      pages: ["Detail", "Deskripsi", "Katalog Tiket", "Bagikan", "Rekomendasi"],
      types: [],
      tab: null,
      detail_product: null,
      selectedType: "Semua",
      isLoading: true,
      CATEGORY: CATEGORY,
      isLogin: false,
    };
  },

  methods: {
    async getProduct() {
      this.isLoading = true;

      var userID = null;
      const userCookies = Cookies.get("user_data");
      if (userCookies) {
        const dataUser = JSON.parse(userCookies);
        userID = dataUser.user_id;
      }

      await this.axios
        .get(`product/${this.$route.params.id}`)
        .then((response) => {
          const product = response.data.response;
          const publishDate = product.product_publish_date;
          product.is_upcoming = moment
            .utc(publishDate)
            .local()
            .isAfter(moment());

          product.product_types = product.product_types.filter(
            (type) => type.is_active == true
          );

          product.product_types = product.product_types.map((type) => {
            const bookmarked = type.bookmarked_by || [];
            type.is_bookmark = bookmarked.find(
              (user) => user.user_id.toString() == userID
            );
            return type;
          });

          product.quantity = product.product_types.reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue.producttype_qty,
            0
          );

          product.is_limited = product.product_types
            .map((type) => type.is_limited)
            .reduce((total, value) => {
              return total && value;
            });

          this.detail_product = product;
          // this.getTypes(response.data.response.product_types);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },

    async getBookmark() {
      return await this.axios
        .get("product/bookmark/get")
        .then((response) => {
          return response.data.response.bookmarks;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally();
    },

    ...mapActions(["addItemToCart", "reduceQty", "emptyCart"]),

    convertDate(isoDate) {
      return utcToLocale(isoDate, "DD-MM-YYYY");
    },
  },
};
</script>

<style scoped>
* {
  scroll-margin-top: 100px;
}
</style>
