const scroll = (id) => {
    const element = document.getElementById(id);
    const app = document.getElementById("app");
    const offset = 100;
    const top =
        element?.getBoundingClientRect().top -
        app?.getBoundingClientRect().top -
        offset;

    window.scrollTo({
        behavior: "smooth",
        top: top,
    });
}

export default scroll