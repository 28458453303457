import html2pdf from "html2pdf.js";

function createPdf(element) {
  const height = element.offsetHeight;
  const width = element.offsetWidth;

  const pdfOption = {
    margin: 5,
    filename: "myfile.pdf",
    image: { type: "jpeg", quality: 1 },
    html2canvas: { scale: 2 },
    jsPDF: {
      unit: "px",
      format: [height + 5, width],
      hotfixes: ["px_scaling"],
      orientation: "p",
    },
  };

  const worker = html2pdf();
  return worker.set(pdfOption).from(element);
}

export { createPdf };
